<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.history") }}
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2-no-top">
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">Mã NCC</th>
            <th role="columnheader" class="text-center">Loại</th>
            <th role="columnheader" class="text-center">Tên</th>
            <th role="columnheader" class="text-center">Thanh toán</th>
            <th role="columnheader" class="text-center">Hình thức</th>
            <th role="columnheader" class="text-center">Mã giao dịch</th>
            <th role="columnheader" class="text-center">Chứng từ</th>
            <th role="columnheader" class="text-center">Ngày ghi nhận</th>
            <th role="columnheader" class="text-center">Ghi chú</th>
            <th role="columnheader" class="text-center">Cập nhật</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in items"
            :key="`h_${index}`"
          >
            <td>{{ item.supplier_code }}</td>
            <td>{{ $t(`labels.supplier_type_${item.supplier_type}`) }}</td>
            <td>{{ item.supplier_name }}</td>
            <td>{{ formatNumber(item.payment_value) }}</td>
            <td>{{ $t(`labels.payment_method_${item.payment_method}`) }}</td>
            <td>{{ item.payment_code }}</td>
            <td>
              <ImageMultipleViewer
                v-if="item.payment_image"
                :images="item.payment_image"
              />
            </td>
            <td>{{ formatDateTime(item.payment_date, "DD/MM/YYYY") }}</td>
            <td>{{ item.note }}</td>
            <td>
              {{ formatDateTime(item.created_at, "HH:mm:ss DD/MM/YYYY") }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "PaymentHistoryLog",
  components: {
    ImageMultipleViewer: () =>
      import("@/components/common/ImageMultipleViewer"),
  },
  props: {
    selectItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getList() {
      httpClient
        .post("/payment-history-log", {
          id: this.selectItem.id,
        })
        .then(({ data }) => {
          this.items = [...data.rows].map((row) => {
            return {
              ...row,
            };
          });
        });
    },
  },
};
</script>

<style scoped></style>
